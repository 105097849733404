<template>
    <div class="injector-enlarged-panel-wrapper">
        <p>Дисплей, на котором отображается количество физраствора (синим) и контраста (зеленым) в шприц-колбах.
            <br/><br/>Также на дисплее при автоматическом заполнении отображается объемы жидкостей, установленные в
            протоколе на рабочей станции.
        </p>
    </div>
</template>

<script>

    export default {
        name: "FluidsAmountScreen",
    }
</script>

<style scoped lang="scss">
    @import "@/scss/components/injector-enlarged-panel-wrappers";
</style>